<template>
  <div>
    <b-container fluid class="content vip_sales">
      <b-row>
        <b-col>
          <b-card>
            <b-form>
              <el-row :gutter="10">
                <el-col :span="4">
                  <select class="form-control" v-model="cur_LocationID">
                    <option value="">{{ $t("Select Store") }}</option>
                    <option
                      v-for="(dt, idx) in storelist"
                      :key="idx"
                      :value="dt.LocationID"
                    >
                      {{ dt.LocationID }} - {{ dt.LocationName }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <SearchAutocomplete
                    :url="apiBaseUrl + '/Web/POS/Vip/search_name'"
                    placeholdertxt="VIP"
                    @input="set_cur_vip_idn"
                    @changing="set_cur_search"
                  ></SearchAutocomplete>
                </el-col>
                <el-col :span="4">
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      :placeholder="$t('Start Date')"
                      @on-open="focus"
                      @on-close="blur"
                      :config="picker_config"
                      class="form-control datepicker"
                      v-model="cur_FromCreateDate"
                    >
                    </flat-picker>
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      :placeholder="$t('End Date')"
                      @on-open="focus"
                      @on-close="blur"
                      :config="picker_config"
                      class="form-control datepicker"
                      v-model="cur_ToCreateDate"
                    >
                    </flat-picker>
                  </base-input>
                </el-col>
                <el-col :span="4" class="text-center">
                  <el-button
                    type="primary"
                    class="mt-2"
                    icon="el-icon-search"
                    size="small"
                    @click="get_data_list(0)"
                    >{{ $t("Search") }}</el-button
                  >
                </el-col>
                <el-col :span="4" class="text-center">
                  <section>
                    <xlsx-workbook>
                      <xlsx-sheet
                        :collection="sheet.data"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <xlsx-download filename="transaction.xlsx">
                        <el-button
                          type="primary"
                          class="mt-2"
                          icon="el-icon-document"
                          size="small"
                          >{{ $t("Export") }}</el-button
                        >
                      </xlsx-download>
                    </xlsx-workbook>
                  </section>
                </el-col>
              </el-row>
            </b-form>
          </b-card>
          <b-card>
            <div class="p-0" v-if="datalist">
              <el-row :gutter="20">
                <el-col :span="24"><h2>VIP Reload Report</h2></el-col>
              </el-row>
              <el-row :gutter="5">
                <el-col :span="6"><h3>Store: {{ datalist.LocationName }} </h3></el-col>
                <el-col :span="4"><h3>VIP: {{ datalist.CustomerNo }} </h3></el-col>
                <el-col :span="8"><h3>{{ datalist.Period }} </h3></el-col>
                <el-col :span="6"><h3>Print: {{ todaystr }} </h3></el-col>
              </el-row>
              <el-row :gutter="0" v-for="(vip, idx) in datalist.vips" :key="idx">
                <div class="top_line"></div>
                <el-col :span="24">
                  <el-row :gutter="10">
                    <el-col :span="6">
                      VIP No: {{ vip.CustomerNo }}
                    </el-col>
                    <el-col :span="6">
                      Name: {{ vip.FirstName + ' ' + vip.LastName }}
                    </el-col>
                  </el-row>
                  <el-row :gutter="0" v-for="(order, idx1) in vip.orders" :key="idx1">
                    <el-col :span="24">
                      <el-row :gutter="10" class="order_heads">
                        <el-col :span="6">
                          <strong>Date Time:</strong> {{ order.CreateDate.substr(0,19) }}
                        </el-col>
                        <el-col :span="6">
                          <strong>Type:</strong> <span v-if="order.Balance>0">{{ order.ProductName }}</span><span v-else>Pay</span>
                        </el-col>
                        <el-col :span="6">
                          <strong>Order No:</strong> {{ order.TransactionNo }}
                        </el-col>
                        <el-col :span="6">
                          <strong>Amount:</strong> {{ parseFloat(order.Balance).toFixed(2) }}
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" class="order_heads mb-3">
                    <el-col :span="6">
                      <strong>VIP Total: {{ parseFloat(vip.vip_total).toFixed(2) }}</strong>
                    </el-col>
                    <el-col :span="6">
                      <strong>Current Balance: {{ parseFloat(vip.vip_account.Balance).toFixed(2) }}</strong>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row :gutter="5">
                <el-col ><h3>Total Amount : {{ parseFloat(order_total).toFixed(2) }}</h3></el-col>
              </el-row>
            </div>
            <div class="p-3" v-else>
              {{ $t("No Records") }}
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
} from "element-ui";
import SearchAutocomplete from "../Components/SearchAutocomplete";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    SearchAutocomplete,
  },
  data() {
    return {
      cur_LocationID: "",
      cur_CustomerIDn: "",
      cur_Search: "",
      cur_FromCreateDate: "",
      cur_ToCreateDate: "",

      todaystr: "",

      storelist: null,
      order_total: 0,

      datalist: null,

      sheets: [{ name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2],[],[1, 2, 2, 2 ]] }],
      // sheets: [{ name: "Sheet1", data: [{ a: 1, b: 2, c: 2 },{ a: 1, b: 2, c: 2, d: 2 },{},{ a: 1, b: 2, c: 2, d: 2 }] }],

      picker_config: {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'Y-m-d',
        maxDate: 'today', // New Date();  YYYY-MM-DD HH:MM
      },

      delay_timer: null,

      token: "",
      user_id: 0,
    };
  },
  watch: {
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    set_cur_search(Search) {
      this.cur_Search = Search;
      this.cur_CustomerIDn = 0;
    },
    set_cur_vip_idn(cIDn) {
      this.cur_CustomerIDn = cIDn;
      this.cur_Search = "";
    },
    fillup_sheet() {
      if (this.datalist.length <= 0) {
        return;
      }

      var dataarr = [];
      dataarr.push([
        'VIP Sales Report',
      ]);
      dataarr.push([
        'Store: ' + this.datalist.LocationName,
        '',
        'VIP: ' + this.datalist.CustomerNo,
        '',
        this.datalist.Period,
        '',
        'PrintDate: ' + this.datalist.PrintDate,
      ]);
      for (var i=0; i<this.datalist.vips.length; i++) {
        dataarr.push([
          '',
        ]);
        dataarr.push([
          'VIP No: ' + this.datalist.vips[i].CustomerNo,
          '',
          'Name: ' + this.datalist.vips[i].FirstName + ' ' + this.datalist.vips[i].LastName,
        ]);
        for (var j=0; j<this.datalist.vips[i].orders.length; j++) {
          dataarr.push([
            'Date Time: ' + this.datalist.vips[i].orders[j].CreateDate.substr(0,19),
            '',
            'Type: ' + ((this.datalist.vips[i].orders[j].Balance > 0) ? this.datalist.vips[i].orders[j].ProductName : 'Pay',
            '',
            'Order No: ' + this.datalist.vips[i].orders[j].TransactionNo,
            '',
            'Amount: ' + parseFloat(this.datalist.vips[i].orders[j].Balance).toFixed(2)),
          ]);
        }
        dataarr.push([
          'VIP Total: ' + parseFloat(this.datalist.vips[i].vip_total).toFixed(2),
          '',
          '',
          'Current Balance: ' + parseFloat(this.datalist.vips[i].vip_account.Balance).toFixed(2),
        ]);
      }
      dataarr.push([]);
      dataarr.push([
        'Total Amount: ' + parseFloat(this.order_total).toFixed(2),
      ]);

      var sheetname = (this.sheets[0].name == 'sheet1')?'defalut':'sheet1'
      this.sheets = [{ name: sheetname, data: dataarr}];
    },
    async get_data_list(init) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("init", init);

      if (this.cur_LocationID) {
        bodyFormData.append("StoreID", this.cur_LocationID);
      }
      if (this.cur_CustomerIDn) {
        bodyFormData.append("CustomerIDn", this.cur_CustomerIDn);
      } else if (this.cur_Search) {
        bodyFormData.append("Search", this.cur_Search);
      }
      if (this.cur_FromCreateDate) {
        bodyFormData.append("FromCreateDate", this.cur_FromCreateDate);
      } else {
        bodyFormData.append("FromCreateDate", this.todaystr);
      }
      if (this.cur_ToCreateDate) {
        bodyFormData.append("ToCreateDate", this.cur_ToCreateDate);
      } else {
        bodyFormData.append("ToCreateDate", this.todaystr);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Vip/reload",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (init) {
                that.storelist = rt.data.locations;
              }
              that.order_total = rt.data.order_total;
              that.datalist = rt.data.datalist;
              that.fillup_sheet();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      var today = new Date();
      this.todaystr = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');

      this.cur_FromCreateDate = this.todaystr;
      this.cur_ToCreateDate = this.todaystr;

      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_data_list(1);
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.vip_sales {
  padding-top: 40px;
}
.vip_sales .search-button {
  margin-top: 2rem;
}
.vip_sales .select_text {
  margin-left: -1rem;
}
.vip_sales .card .table th {
  padding: 0rem;
}
.vip_sales .card .table td {
  padding: 0rem;
}
.vip_sales .picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.vip_sales .el-collapse-item__header {
  background-color: #f0f0f0;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.vip_sales .el-collapse-item__content {
  padding-bottom: 0.5rem;
}
.vip_sales #mymodalclass .modal-title {
  font-size: 2rem !important;
}
.vip_sales #mymodalclass .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.vip_sales .form-group {
  margin-bottom: 0.5rem;
}
.vip_sales .top_line {
  height: 1px;
  background: black;
}
.vip_sales .order_heads {
  background-color: #cccccc;
}
</style>
